import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

class PressKitPage extends React.Component {
  render() {
    const pk = this.props.data.contentfulPressKit
    console.log(pk)
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <section className="top">
          <Helmet title={pk.title} />
          <h1 className="hero-title">{pk.title}</h1>
        </section>
        <section>
          <header>
            <p className="section-number">0{(sectionCount += 1)}</p>
            <h2 id="intro">Intro</h2>
          </header>
          <div
            className="mt section-description"
            dangerouslySetInnerHTML={{
              __html: pk.intro.childMarkdownRemark.html,
            }}
          />
        </section>
        <section className="bkd-ash">
          <header>
            <p className="section-number">0{(sectionCount += 1)}</p>
            <h2 id="bios">Bios</h2>
          </header>
          <div className="mt section-description">
            <h3 id="short-bio">Short Bio</h3>
          </div>
          <div
            className="section-description"
            dangerouslySetInnerHTML={{
              __html: pk.shortBio.childMarkdownRemark.html,
            }}
          />
          <div className="mt section-description">
            <h3 id="long-bio">Long Bio</h3>
          </div>
          <div
            className="section-description"
            dangerouslySetInnerHTML={{
              __html: pk.longBio.childMarkdownRemark.html,
            }}
          />
        </section>
        <section className="bkd-jade">
          <header>
            <p className="section-number">0{(sectionCount += 1)}</p>
            <h2 id="images">Images</h2>
          </header>
          <ul className="book-list tac">
            <li>
              <a href={pk.img1Full.url}>
                <GatsbyImage
                  image={pk.image1.gatsbyImageData}
                  alt=""
                  style={{ minHeight: `394px` }}
                />
              </a>
              <div
                dangerouslySetInnerHTML={{
                  __html: pk.image1Caption.childMarkdownRemark.html,
                }}
              />
              <a className="button mobile-full" href={pk.img1Full.url}>
                Download High Res
              </a>
            </li>
            <li>
              <a href={pk.img2Full.url}>
                <GatsbyImage
                  image={pk.image2.gatsbyImageData}
                  alt=""
                  style={{ minHeight: `394px` }}
                />
              </a>
              <div
                dangerouslySetInnerHTML={{
                  __html: pk.image2Caption.childMarkdownRemark.html,
                }}
              />
              <a className="button mobile-full" href={pk.img2Full.url}>
                Download High Res
              </a>
            </li>
            <li>
              <a href={pk.img3Full.url}>
                <GatsbyImage
                  image={pk.image3.gatsbyImageData}
                  alt=""
                  style={{ minHeight: `394px` }}
                />
              </a>
              <div
                dangerouslySetInnerHTML={{
                  __html: pk.image3Caption.childMarkdownRemark.html,
                }}
              />
              <a className="button mobile-full" href={pk.img3Full.url}>
                Download High Res
              </a>
            </li>
          </ul>
        </section>
      </Layout>
    )
  }
}

export default PressKitPage

export const pageQuery = graphql`
  query PressKitQuery {
    contentfulPressKit(id: { eq: "cb03e57f-c5c3-56d3-9012-0fa55ce7c79b" }) {
      image1 {
        gatsbyImageData(height: 384, width: 256, layout: CONSTRAINED)
      }
      img1Full: image1 {
        url
      }
      image1Caption {
        childMarkdownRemark {
          html
        }
      }
      image2 {
        gatsbyImageData(height: 384, width: 256, layout: CONSTRAINED)
      }
      img2Full: image2 {
        url
      }
      image2Caption {
        childMarkdownRemark {
          html
        }
      }
      image3 {
        gatsbyImageData(height: 384, width: 256, layout: CONSTRAINED)
      }
      img3Full: image3 {
        url
      }
      image3Caption {
        childMarkdownRemark {
          html
        }
      }
      intro {
        childMarkdownRemark {
          html
        }
      }
      longBio {
        childMarkdownRemark {
          html
        }
      }
      shortBio {
        childMarkdownRemark {
          html
        }
      }
      title
    }
  }
`
